.date-picker-container {
  .date-picker-wrapper {
    position: relative;
    .form-control {
      padding-right: 1.75rem;
    }
    .calendar-icon {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      font-size: 20px;
      line-height: 1em;
      pointer-events: none;
    }
  }
}
#root[data-bs-theme="dark"] {
  .date-picker-container {
    .date-picker-wrapper {
      .react-datepicker,
      .react-datepicker__header {
        border: 1px solid $dark--border-color;
        @include base-style-dark;
      }
      .react-datepicker__day,
      .react-datepicker__day-name,
      .react-datepicker__current-month {
        color: $dark--text-color;
      }
      .react-datepicker__day--weekend,
      .react-datepicker__day--outside-month {
        color: $dark-text-color;
      }
    }
  }
}
